import React, { useRef, useState, useEffect } from 'react';

export function MeiliCard({ children }: { children: React.ReactNode }) {
    const cardRef = useRef<HTMLDivElement>(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        if (!cardRef.current) return;
        const card = cardRef.current;

        const handleMouseMove = (e: MouseEvent) => {
            if (cardRef.current) {
                const rect = cardRef.current.getBoundingClientRect();
                setMousePosition({
                    x: e.clientX - rect.left,
                    y: e.clientY - rect.top,
                });
            }
        };

        card.addEventListener('mousemove', handleMouseMove);

        return () => {
            card.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    const dynamicStyles = {
        '--x': `${mousePosition.x}px`,
        '--y': `${mousePosition.y}px`,
    } as React.CSSProperties;

    return (
        <div
            ref={cardRef}
            style={dynamicStyles}
            className="border-gradient group relative overflow-hidden rounded-2xl border border-b-0 border-white/5 bg-[#201E22] bg-opacity-80 p-4 before:absolute before:-inset-px before:h-[calc(100%+2px)] before:w-[calc(100%+2px)] before:rounded-xl before:blur-xl lg:p-8"
        >
            <div className="relative">{children}</div>
        </div>
    );
}
