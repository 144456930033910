import { Headline } from '~/components/General/Headline';
import { MeiliCard } from '~/components/General/MeiliCard';
import { PrimaryButton } from '~/components/General/PrimaryButton';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

export function Pricing() {
    return (
        <section
            id="pricing"
            className="group container min-h-[80vh] items-center"
        >
            <Headline title="Exclusively for" titleColored="the Go-Getters">
                The app has been designed to foster your continual growth,
                supporting your dating journey, and helping you thrive with
                authentic and meaningful connections. But it requires you to
                commit.
            </Headline>

            <div className="relative">
                <div className="grid grid-cols-1 gap-8 text-white sm:grid-cols-2 lg:grid-cols-3">
                    {pricing.map((plan) => (
                        <MeiliCard key={plan.name} className="flex flex-col">
                            <div className="item flex flex-col items-start">
                                <h2 className="mb-4 text-2xl font-bold">
                                    {plan.name}
                                </h2>
                                <p className="font-semibold">{plan.price}</p>
                                <a href={plan.buttonLink}>
                                    <PrimaryButton className="mb-4 mt-8">
                                        {plan.buttonText}
                                    </PrimaryButton>
                                </a>
                                <ul className="mt-12 list-inside space-y-2">
                                    {plan.features.map((feature) => (
                                        <li
                                            key={feature}
                                            className="flex gap-1"
                                        >
                                            {/* <CheckOutlinedIcon className="fill-current text-green-400" /> */}
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </MeiliCard>
                    ))}
                </div>

                <div className="absolute left-0 top-0 h-full w-full text-2xl font-bold text-gray-500 transition-all duration-300 group-hover:text-white group-hover:backdrop-blur-sm">
                    <span className="flex h-full w-full items-center justify-center rounded-lg bg-black bg-opacity-50 p-2">
                        Available free of charge, while in early public access.
                    </span>
                </div>
            </div>
        </section>
    );
}

const pricing = [
    {
        name: 'Basic',
        price: '67 EUR/month',
        features: [
            'Import your chats from Tinder seamlessly',
            'Instant search experience through your conversations',
            'Breakdown and learn from your past conversations',
        ],
        buttonText: 'Currently closed.',
        buttonLink: '',
    },
    {
        name: 'Premium',
        price: '117 EUR/month',
        features: [
            'Everything in Basic',
            'Leverage AI features to enhance and guide your conversations',
            'Get an edge with ChadAI, your personal conversation assistant',
            'Access to the Chrome extension to assist you while using Tinder',
            'Prioritized support for a seamless experience',
            'Lifetime access to all library features after maintaining a membership for a year',
        ],
        buttonText: 'Currently closed.',
        buttonLink: '',
    },
    {
        name: 'Enterprise',
        price: 'Custom Pricing',
        features: [
            'Everything in Premium, fully white-labeled for your brand',
            'Integration with your own custom knowledge base',
            'Personalized AI assistant for individual members of your team',
            'Dedicated account manager for seamless integration and support',
            'Customized conversation formats based on your needs',
            'Priority access to new features and improvements',
        ],
        buttonText: 'Schedule a Call',
        buttonLink: 'mailto:hi@thetext.app',
    },
];
